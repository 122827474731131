import { Aurelia, bindable, inject } from 'aurelia-framework';
import { AuthService, FetchConfig }  from 'aurelia-auth';
import { AppContainer }              from 'resources/services/app-container';
import { BaseViewModel }             from 'base-view-model';
import { CustomHttpClient }          from 'resources/services/custom-http-client';
import environment                   from 'environment';

@inject(Aurelia, AppContainer, AuthService, CustomHttpClient, FetchConfig)
export class RequestPasswordReset extends BaseViewModel {

    /**
     * Form alert
     *
     * @type {{}}
     */
    @bindable alert = {};

    /**
     * Email field
     *
     * @type {string}
     */
    email = '';

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     * @param authService
     * @param httpClient
     * @param fetchConfig
     */
    constructor(aurelia, appContainer, authService, httpClient, fetchConfig) {
        super(appContainer);

        this.aurelia     = aurelia;
        this.auth        = authService;
        this.httpClient  = httpClient;
        this.fetchConfig = fetchConfig;
        this.environment = environment;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        super.activate(params);

        this.configureHttpClient();
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'login-container';
    }

    /**
     * Configures the HTTP client
     */
    configureHttpClient() {
        this.fetchConfig.configure();

        // configures aurelia-auth fetch client to include application locale
        this.fetchConfig.httpClient.configure(config => {
            config.withDefaults({
                headers: {
                    'Application-Locale': this.appContainer.i18n.getLocale(),
                },
            });
        });
    }

    /**
     * Handles form submission
     */
    submit() {
        this.alert = {};

        if (this.validate()) {
            this.httpClient
                .post('password-resets/request', { email: this.email })
                .then(response => {
                    if (response.status === true) {
                        this.alert = this.alertMessage(response.status, this.appContainer.i18n.tr('text.an-email-will-be-sent-with-instructions'), response.errors);
                    } else {
                        let message = response.errors instanceof Array ? response.errors[0] : response.message;

                        this.alert = this.alertMessage(response.status, message);
                    }
                });
        }
    }

    /**
     * Validates the form
     *
     * @returns {boolean}
     */
    validate() {
        return this.email.length > 0;
    }

    /**
     * Navigates back to the login page
     */
    navigateToLogin() {
        this.appContainer.router.navigateToRoute('login');
    }

}
